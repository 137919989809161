import { firebase } from '@/infra/SiteRepository'
import { User } from '@/domain/User'

export async function initUser(): Promise<User | null> {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  return new Promise(resolve => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      resolve(user as User)
      unsubscribe()
    })
  })
}
