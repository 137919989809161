import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { firebase } from '@/infra/SiteRepository'

import SiteList from '../views/SiteList/SiteList.vue'
import Signin from '@/views/SignIn/Signin.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'SiteList',
    component: SiteList,
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'SiteList' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  // 認証済み or Signinページ
  if (
    firebase.auth().currentUser ||
    to.matched.some(record => record.name === 'Signin')
  ) {
    next()
    return
  }

  // 未認証なら現ページのpathをつけてSigninページへ
  next({
    name: 'Signin',
    query: {
      redirect: to.fullPath,
    },
  })
})

export default router
