
import { defineComponent, ref } from 'vue'
import SiteListItem from '@/views/SiteList/SiteListItem.vue'
import { SiteHook } from '@/hooks/SiteHook'
import {
  SiteQuery,
  STATUS_HOLD,
  STATUS_NG,
  STATUS_NO_CHECK,
  STATUS_OK,
} from '@/domain/Site'

interface Tab extends SiteQuery {
  label: string
}
const _tabs: Tab[] = [
  {
    label: '未チェック',
    score: STATUS_NO_CHECK,
  },
  {
    label: 'BAN候補',
    score: STATUS_NG,
  },
  {
    label: 'BAN',
    ban: true,
  },
  {
    label: 'OK',
    score: STATUS_OK,
  },
]

export default defineComponent({
  components: {
    SiteListItem,
  },
  setup() {
    const tabs = ref(_tabs)
    const currentTab = ref(tabs.value[0])
    const state = SiteHook(currentTab.value)
    return {
      tabs,
      checkCurrentTab: (tab: Tab) => currentTab.value.label === tab.label,
      siteList: state.list,
      where: (tab: Tab) => {
        currentTab.value = tab
        state.where(tab)
      },
      hasMore: state.hasMore,
      more: state.more,
    }
  },
})
