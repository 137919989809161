<template>
  <div class="SiteList">
    <nav class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.score"
        class="tab"
        :selected="checkCurrentTab(tab)"
        @click="where(tab)"
      >
        {{ tab.label }}
      </div>
    </nav>
    <div class="count-block">
      <div class="count-length">{{ siteList.length }}</div>
      <div class="count-label">件</div>
    </div>
    <div class="site-list" v-if="siteList.length > 0">
      <transition-group name="list" class="list-container" tag="div">
        <site-list-item
          v-for="site in siteList"
          class="site"
          :key="site.id"
          :site="site"
        />
      </transition-group>
      <div class="more-button" v-if="hasMore" @click="more()">もっと見る</div>
    </div>
    <div v-else>
      <p>
        no items
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SiteListItem from '@/views/SiteList/SiteListItem.vue'
import { SiteHook } from '@/hooks/SiteHook'
import {
  SiteQuery,
  STATUS_HOLD,
  STATUS_NG,
  STATUS_NO_CHECK,
  STATUS_OK,
} from '@/domain/Site'

interface Tab extends SiteQuery {
  label: string
}
const _tabs: Tab[] = [
  {
    label: '未チェック',
    score: STATUS_NO_CHECK,
  },
  {
    label: 'BAN候補',
    score: STATUS_NG,
  },
  {
    label: 'BAN',
    ban: true,
  },
  {
    label: 'OK',
    score: STATUS_OK,
  },
]

export default defineComponent({
  components: {
    SiteListItem,
  },
  setup() {
    const tabs = ref(_tabs)
    const currentTab = ref(tabs.value[0])
    const state = SiteHook(currentTab.value)
    return {
      tabs,
      checkCurrentTab: (tab: Tab) => currentTab.value.label === tab.label,
      siteList: state.list,
      where: (tab: Tab) => {
        currentTab.value = tab
        state.where(tab)
      },
      hasMore: state.hasMore,
      more: state.more,
    }
  },
})
</script>

<style scoped lang="scss">
.SiteList {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 40px;
}

.tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  height: 48px;
  .tab {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid var(--text-color-5);
    color: var(--text-color-3);
    font-size: 14px;
    &:hover {
      font-weight: 700;
      border-bottom: 1px solid var(--text-color-2);
    }
    &[selected='true'] {
      color: var(--text-color-1);
      font-weight: 700;
      pointer-events: none;
      border-bottom: 2px solid var(--text-color-1);
    }
  }
}
.count-block {
  display: flex;
  align-items: center;
}
.count-label {
  font-weight: 700;
  font-size: 12px;
  margin-right: 8px;
}

.count-length {
  font-weight: 700;
}
.site-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list-container {
  margin-bottom: 32px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.25s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
}
.more-button {
  background: var(--text-color-1);
  color: var(--base-color-1);
  font-weight: 700;
  font-size: 14px;
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
