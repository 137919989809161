import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { db } from '@/infra/firestore'
import { firestore } from 'firebase'
import {
  SiteScore,
  Site,
  SiteQuery,
  STATUS_HOLD,
  STATUS_NG,
} from '@/domain/Site'
import axios from 'axios'

const sites = db.collection('sites')

async function setScore(id: string, score: SiteScore) {
  if (score === STATUS_HOLD) {
    await sites.doc(id).delete()
  } else {
    await sites.doc(id).update({
      score,
      '_meta.checkedAt': firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}

async function setBanStatus(id: string, ban: boolean) {
  await sites.doc(id).update({
    ban,
    '_meta.banedAt': firebase.firestore.FieldValue.serverTimestamp(),
  })
}

async function addSite(subdomain: string): Promise<void> {
  const site = await sites.where('subdomain', '==', subdomain).get()
  if (!site.empty) {
    console.warn(`${subdomain} is already exist.`)
    return
  }
  await sites.add({
    subdomain,
    score: 0,
    _meta: {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      publishedAt: firebase.firestore.FieldValue.serverTimestamp(),
      checkedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
  })
  console.warn(`${subdomain} added.`)
}

export { sites, firebase, setScore, addSite, setBanStatus }
export const SiteConverter: firestore.FirestoreDataConverter<Site> = {
  toFirestore(site: Site): firestore.DocumentData {
    if (site.ban) {
      return {
        score: site.score,
        subdomain: site.subdomain,
        ban: 1,
        ...site._meta,
      }
    } else {
      return {
        score: site.score,
        subdomain: site.subdomain,
        ...site._meta,
      }
    }
  },
  fromFirestore(
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions
  ): Site {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      ...data,
    } as Site
  },
}

export function getSites(param: {
  siteQuery: SiteQuery
  limit?: number
  callback: (sites: Site[]) => void
}): () => void {
  const { siteQuery, limit = 100, callback } = param
  let requestQuery = sites
    .orderBy('_meta.checkedAt', 'desc')
    .limit(limit)
    .where('ban', '==', !!siteQuery.ban)
  if (siteQuery.score !== null && siteQuery.score !== undefined) {
    requestQuery = requestQuery.where('score', '==', siteQuery.score)
  }

  return requestQuery.withConverter(SiteConverter).onSnapshot(snapshot => {
    const list = snapshot.docs.map(d => d.data())
    if (siteQuery.score === STATUS_NG) {
      callback(list.filter(d => !d.ban))
    } else {
      callback(list)
    }
  })
}

export async function banSite(site: Site) {
  try {
    await axios.post(
      'https://api.studiodesignapp.com/api/v2/account/ban/domain',
      {
        domain: `${site.subdomain}.studio.site`,
        code: '97YMcmiDrsXg',
      }
    )
  } catch (e) {
    return
  }

  await setBanStatus(site.id, true)
}
