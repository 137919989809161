import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { userSymbol, UserHook } from './hooks/UserHook'
import { initUser } from '@/infra/UserRepository'
initUser().then(() => {
  createApp(App as any)
    .provide(userSymbol, UserHook())
    .use(router)
    .mount('#app')
})
