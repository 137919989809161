<template>
  <div v-if="user">
    <p>{{ user.displayName }} ({{ user.email }})</p>
    <img :src="user.photoURL" class="photo" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useUser } from '@/hooks/UserHook'

export default defineComponent({
  setup() {
    return {
      user: useUser()?.user,
    }
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.photo {
  border-radius: 50%;
  width: 80px;
}
</style>
