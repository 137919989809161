<template>
  <the-user />
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheUser from '@/views/SignIn/TheUser.vue'

export default defineComponent({
  components: {
    TheUser,
  },
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
:root {
  --base-color-1: white;
  --base-color-2: #ccc;
  --base-color-3: #999;
  --base-color-4: #666;
  --base-color-5: #333;
  --base-color-6: #000;
  --text-color-1: black;
  --text-color-2: #333;
  --text-color-3: #666;
  --text-color-4: #999;
  --text-color-5: #ccc;
  --text-color-6: #fff;
  --border-color: black;
}
</style>
