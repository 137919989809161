
import { defineComponent } from 'vue'
import { useUser } from '@/hooks/UserHook'

export default defineComponent({
  setup() {
    return {
      user: useUser()?.user,
    }
  },
})
