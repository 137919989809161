
import { computed, defineComponent, PropType } from 'vue'
import * as siteRepository from '@/infra/SiteRepository'
import { firestore } from 'firebase'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Site, SiteScore } from '@/domain/Site'
import { banSite } from '@/infra/SiteRepository'
import LazyIframe from '@/components/LazyIframe.vue'
dayjs.extend(relativeTime)

export default defineComponent({
  props: {
    site: {
      type: Object as PropType<Site>,
      required: true,
    },
  },
  components: {
    LazyIframe,
  },
  setup(props) {
    const url = computed(() => `https://${props.site.subdomain}.studio.site`)

    function toDate(timeStamp?: firestore.Timestamp) {
      if (timeStamp == null) return ''
      // relative
      return dayjs().to(dayjs(timeStamp.toDate()))
    }

    const checkedAt = computed(() => toDate(props.site._meta.checkedAt))
    const createdAt = computed(() => toDate(props.site._meta.createdAt))

    function setScore(score: SiteScore) {
      siteRepository.setScore(props.site.id, score)
    }

    return {
      url,
      setScore,
      checkedAt,
      createdAt,
      banSite,
    }
  },
})
