import { reactive, toRefs } from 'vue'
import { getSites } from '@/infra/SiteRepository'
import { Site, SiteQuery } from '@/domain/Site'

const DEFAULT_PAGE_LENGTH = 100

export function SiteHook(siteQuery: SiteQuery) {
  // reactiveでデータを記述
  const state = reactive({
    list: [] as Site[],
    hasMore: false,
    unsubscribe: new Function(),
    page: 1,
    lastSiteQuery: siteQuery,
  })
  // メソッド

  function load() {
    const limit = state.page * DEFAULT_PAGE_LENGTH + 1
    state.unsubscribe = getSites({
      siteQuery: state.lastSiteQuery,
      limit,
      callback: sites => {
        state.hasMore = sites.length === limit
        if (state.hasMore) {
          state.list = sites.slice(0, limit - 1)
        } else {
          state.list = sites
        }
      },
    })
  }

  function where(query: SiteQuery) {
    state.page = 1
    state.lastSiteQuery = query
    state.unsubscribe()
    state.hasMore = false
    load()
  }

  function more() {
    if (!state.hasMore) {
      return
    }
    state.page++
    load()
  }

  where(siteQuery)

  return {
    ...toRefs(state),
    where,
    more,
  }
}
