import { firestore } from 'firebase'

export type Site = {
  id: string
  subdomain: string
  score: number
  ban?: 1
  _meta: {
    createdAt: firestore.Timestamp
    publishedAt: firestore.Timestamp
    checkedAt: firestore.Timestamp
  }
}

export const STATUS_NO_CHECK = 0
export const STATUS_NG = -2
export const STATUS_HOLD = -1
export const STATUS_OK = 1
export type SiteScore = 0 | -2 | -1 | 1

export interface SiteQuery {
  score?: SiteScore
  ban?: boolean
}
