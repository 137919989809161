<template>
  <div class="site">
    <div class="preview">
      <LazyIframe :src="url" />
    </div>
    <div class="control">
      <button @click="setScore(1)">OK</button>
      <button @click="setScore(-1)">次回確認</button>
      <button v-if="site.score !== -2" @click="setScore(-2)">
        BAN候補
      </button>
      <button @click="banSite(site)" v-if="site.score === -2">BAN</button>
    </div>
    <h2 class="subdomain">
      <a :href="url" target="_blank" rel="noopener noreferrer">{{ url }}</a>
    </h2>
    <div>
      <div>checked: {{ checkedAt }}</div>
      <div>created: {{ createdAt }}</div>
      <div>評価: {{ site.score }}</div>
      <div v-if="site.ban">BAN</div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import * as siteRepository from '@/infra/SiteRepository'
import { firestore } from 'firebase'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Site, SiteScore } from '@/domain/Site'
import { banSite } from '@/infra/SiteRepository'
import LazyIframe from '@/components/LazyIframe.vue'
dayjs.extend(relativeTime)

export default defineComponent({
  props: {
    site: {
      type: Object as PropType<Site>,
      required: true,
    },
  },
  components: {
    LazyIframe,
  },
  setup(props) {
    const url = computed(() => `https://${props.site.subdomain}.studio.site`)

    function toDate(timeStamp?: firestore.Timestamp) {
      if (timeStamp == null) return ''
      // relative
      return dayjs().to(dayjs(timeStamp.toDate()))
    }

    const checkedAt = computed(() => toDate(props.site._meta.checkedAt))
    const createdAt = computed(() => toDate(props.site._meta.createdAt))

    function setScore(score: SiteScore) {
      siteRepository.setScore(props.site.id, score)
    }

    return {
      url,
      setScore,
      checkedAt,
      createdAt,
      banSite,
    }
  },
})
</script>

<style scoped lang="scss">
.site {
  padding: 8px;
  margin-bottom: 10px;

  .preview {
    width: 400px;
    height: 200px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }

  h2 {
    margin: 0;

    a {
      text-decoration: none;
      font-size: 12px;
      color: #333;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.control {
  display: flex;
  justify-content: space-between;
}
</style>
