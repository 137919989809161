import firebase from 'firebase'

const config = {
  apiKey: 'AIzaSyCxNXUJzGTVFchQkB816K8bsxNlmZpafBo',
  authDomain: 'site-checker-63e88.firebaseapp.com',
  databaseURL: 'https://site-checker-63e88.firebaseio.com',
  projectId: 'site-checker-63e88',
  storageBucket: 'site-checker-63e88.appspot.com',
  messagingSenderId: '44317367307',
  appId: '1:44317367307:web:277618ffca39446b333b0e',
  measurementId: 'G-VQ816P25RQ',
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
export const db = firebase.firestore()
