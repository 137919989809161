
import { defineComponent, ref, onMounted } from 'vue'
import { firebase } from '@/infra/SiteRepository'
import { useUser } from '@/hooks/UserHook'
import { useRouter, useRoute } from 'vue-router'
import { User } from '@/domain/User'

const signing = 'signing'
const authHd = 'studio.design'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const isSigning = ref(route.query.state === signing)
    const userHook = useUser()

    onMounted(async () => {
      // firebase authから戻ってきた場合に処理する
      if (!isSigning.value) return

      // ユーザー情報をstoreに保存して元のページに戻す
      const result = await firebase.auth().getRedirectResult()
      const { user, additionalUserInfo } = result
      const hd = (additionalUserInfo?.profile as Record<string, string>)?.hd
      if (!user || hd !== authHd) return

      userHook?.setUser(user as User)
      await router.push({ path: (route.query.redirect as string) || '/' })
    })

    async function signIn() {
      // routeをサインイン処理中にセットしてfirebase auth実行
      await router.push({
        query: {
          ...route.query,
          state: signing,
        },
      })

      const provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        hd: authHd,
      })

      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      await firebase.auth().signInWithRedirect(provider)
    }

    return {
      isSigning,
      signIn,
    }
  },
})
