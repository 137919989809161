import { reactive, inject, InjectionKey } from 'vue'
import { User } from '@/domain/User'

export const UserHook = () => {
  const state = reactive({
    user: null as User | null,
  })
  return {
    get user() {
      return state.user
    },
    setUser(user: User) {
      state.user = user
    },
  }
}

export const userSymbol: InjectionKey<ReturnType<typeof UserHook>> = Symbol(
  'userSymbol'
)

export const useUser = () => inject(userSymbol)
