
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  name: 'LazyIframe',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const iframeRef = ref<HTMLIFrameElement | null>(null)
    const isIframeLoading = ref(true)
    const onIframeLoaded = () => {
      isIframeLoading.value = false
    }

    onMounted(() => {
      // iframe srcをlazy load
      const iframeElement = iframeRef.value
      if (!iframeElement) return

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return
          iframeElement.src = props.src

          iframeElement.addEventListener('load', onIframeLoaded)
          observer.unobserve(iframeElement)
        })
      }, {})
      observer.observe(iframeElement)
    })

    onUnmounted(() => {
      const iframeElement = iframeRef.value
      if (!iframeElement) return
      iframeElement.removeEventListener('load', onIframeLoaded)
    })

    return {
      iframeRef,
      isIframeLoading,
    }
  },
})
